<template>
  <q-page>
    <div class="row full-width">
      <q-table
          :columns="columns"
          :rows="store.state.banners.data"
          :rows-per-page-options="[lengthData]"
          class="full-width"
          hide-bottom
          row-key="index"
          title="Баннеры"
          @request="getBanners">
        <template v-slot:top-right>
          <q-btn :to="$route.fullPath + '/addedit'" color="accent" icon="add" label="Добавить" size="sm"/>

          <q-btn :disabled="store.state.banners.positions.length === 0" color="accent" icon="done"
                 label="Сохранить расположение"
                 size="sm"
                 @click="savePosition"/>
        </template>
        <template v-slot:body="props">

          <q-tr :id="props.row.position" :props="props" draggable="true" @dragend="droped($event)"
                @dragover="dragover($event, props.row)" @dragstart="dragit($event, props.row)">
            <q-td class="text-center">{{ props.row.position }}</q-td>
            <q-td class="text-center">{{ props.row.id }}</q-td>

            <q-td class="text-left">
              <q-img :src="props.row.image" fit="contain" loading="lazy" style="width: 80px; height: 80px;">
              </q-img>
            </q-td>

            <!--						<q-td class="text-center">{{ props.row. }}</q-td>-->
            <q-td class="text-center">
              <long-text-field :maxlen="100" :value="props.row.link"/>
            </q-td>
            <!--            <q-td class="text-center">{{ props.row.link }}</q-td>-->
            <q-td class="text-center">
              <q-btn
                  :to="$route.fullPath +'/addedit/'+props.row.id"
                  dense
                  icon="edit"
                  round
                  size="sm"
                  @click="addEditRedirect(props.row)">
                <q-tooltip>Редактировать</q-tooltip>
              </q-btn>
              <q-btn
                  dense
                  icon="delete"
                  round
                  size="sm"
                  @click="deleteBanner(props.row.id)">
                <q-tooltip>Удалить</q-tooltip>
              </q-btn>

            </q-td>
          </q-tr>

        </template>

      </q-table>
    </div>
  </q-page>
</template>

<script>
import {defineComponent, onMounted, ref} from 'vue';
import {BannerApi} from '@/api/apis/banner-api.ts';
import {useStore} from 'vuex';
import LongTextField from '@/components/text/longTextField';
import {Notify} from 'quasar';

const columns = [
  {
    name: 'position',
    field: 'position',
    label: 'Позиция',
    align: 'center',
  },
  {
    name: 'id',
    field: 'id',
    align: 'center',
    label: 'Id',
  },
  {
    name: 'image',
    field: 'image',
    align: 'left',
    label: 'Изображение баннера',
  },
  {
    name: 'link',
    field: 'link',
    align: 'center',
    label: 'Ссылка',
  },
  {
    name: 'Action',
    label: '',
    field: 'Action',
    sortable: false,
    align: 'left',
  },
];

export default defineComponent({
  components: {LongTextField},
  setup() {
    const store = useStore();
    const moveItem = ref(null);
    const loading = ref(false);
    const lengthData = ref(0);

    async function getBanners() {
      loading.value = true;
      const response = await new BannerApi().bannerGetItems();
      store.dispatch('updateBanners', response.data);
      lengthData.value = response.data.length;
      loading.value = false;
    }

    onMounted(() => getBanners());

    const dragit = (event, item) => {
      moveItem.value = item;
    };

    const dragover = (e, item) => {
      const cloneBanners = [...store.state.banners.data];
      const ItemIndex = cloneBanners.indexOf(moveItem.value);
      const woveItemIndex = cloneBanners.indexOf(item);
      cloneBanners.splice(ItemIndex, 1);
      cloneBanners.splice(woveItemIndex, 0, moveItem.value);
      store.dispatch('updateBanners', cloneBanners);
    };
    const droped = () => {
      store.dispatch('updateBannersDropped', store.state.banners.data);
    };

    const addEditRedirect = (item) => {
      store.dispatch('editBannerItemUpdate', item);
    };

    async function deleteBanner(id) {
      const response = await new BannerApi().deleteBanner(id);
      if (response.status === 202) {
        store.dispatch('deleteBannerItem', id);
      }
    }

    async function savePosition() {
      const response = await new BannerApi().updatePositionBanner(store.state.banners.positions);
      if (response.status === 202) {
        store.dispatch('clearBannerPositions');
        Notify.create({
          type: 'positive',
          message: 'Сохранено',
        });
      }
    }

    return {
      lengthData,
      addEditRedirect,
      deleteBanner,
      savePosition,
      dragover,
      dragit,
      droped,
      getBanners,
      columns,
      store,

    };
  },
});
</script>
<style>
.q-btn {
  margin-left: 10px;
}
</style>
